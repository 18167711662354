import { curveBasis, extent, line, max, min, scaleLinear, scaleLog } from 'd3';

import { ChartWrapper } from '../components/ChartWrapper';
import { layoutPdc as layout } from '../../config/chartLayout';
import { AxisLeft } from '../components/AxisLeft';
import { AxisBottom } from '../components/AxisBottom';
import { WaitingForDataChart } from '../components/WaitingForDataChart';

import {
  PdcDurations,
  calculatePdcPoints,
  secondsToShortTimeString,
} from '../../helpers/criticalPowerHelpers';

export const PowerVsTimeChart = ({ data }) => {
  const { width, height, margin } = layout;
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  if (!data) return <WaitingForDataChart layout={layout} />;

  // generate data points
  const timeDomain = [min(PdcDurations), max(PdcDurations)];
  const dataPoints = calculatePdcPoints(
    data.CriticalPower,
    Array.from(
      { length: Math.floor((timeDomain[1] - timeDomain[0]) / 10) + 1 },
      (_, i) => min(PdcDurations) + i * 10,
    ),
  );

  const xDomain = [0, max(PdcDurations)];
  const xScale = scaleLinear().domain(xDomain).range([0, innerWidth]);

  const xTicks = [120, 180, 300, 600, 900, 1200, 1800, 3600];

  // const yDomain = [0, max(dataPoints, (d) => d.power) * 1.1];
  const yDomain = [
    min([max(dataPoints, (d) => d.power) * 0.7, data.CriticalPower.cp * 0.9]),
    max(dataPoints, (d) => d.power) * 1.1,
  ];
  const yScale = scaleLinear().domain(yDomain).range([innerHeight, 0]).nice();

  const pdCurveGenerator = line()
    .x((d) => xScale(d.duration))
    .y((d) => yScale(d.power))
    .curve(curveBasis);

  // 5 min power
  const dur5min = 300;
  const pwr5min = (
    data.CriticalPower.wPrime * 1000 * (1 / dur5min) +
    data.CriticalPower.cp
  ).toFixed(1);

  // find duration @ CP
  // y = wPrime * 1000 * x + cp
  // when y = cp
  // wPrime * 1000 * x = 0

  return (
    <ChartWrapper layout={layout}>
      <g
        key="p-vs-time-chart-canvas"
        id="p-vs-time-chart-canvas"
        transform={`translate(${margin.left},${margin.top})`}
      >
        <AxisBottom
          xScale={xScale}
          innerWidth={innerWidth}
          innerHeight={innerHeight}
          ticks={xTicks}
          tickFormat={(t) => secondsToShortTimeString(t)}
          tickTextOffset={30}
          tickTextRotate={-45}
          axisLabelText="Duration"
          axisLabelTextOffset={70}
        />
        <AxisLeft
          yScale={yScale}
          innerHeight={innerHeight}
          tickTextOffset={-25}
          axisLabelText="Power (W)"
          axisLabelTextOffset={-60}
        />
        {/* PDC */}
        <path className="chart-pdc-line" d={pdCurveGenerator(dataPoints)} />
        {/* Test #1 */}
        <circle
          key="pdc-test-point-1"
          className="chart-pdc-test-point"
          cx={xScale(data.TestResults[0].duration)}
          cy={yScale(data.TestResults[0].power)}
          r="5"
        />
        <text
          key="pdc-test-point1-text"
          className="chart-pdc-test-point-text"
          x={xScale(data.TestResults[0].duration)}
          y={yScale(data.TestResults[0].power) - 20}
        >{`Test #1: ${secondsToShortTimeString(data.TestResults[0].duration)}@${
          data.TestResults[0].power
        }W`}</text>
        {/* Test #2 */}
        <circle
          key="pdc-test-point-2"
          className="chart-pdc-test-point"
          cx={xScale(data.TestResults[1].duration)}
          cy={yScale(data.TestResults[1].power)}
          r="5"
        />
        <text
          key="pdc-test-point2-text"
          className="chart-pdc-test-point-text"
          x={xScale(data.TestResults[1].duration)}
          y={yScale(data.TestResults[1].power) - 20}
        >{`Test #2: ${secondsToShortTimeString(data.TestResults[1].duration)}@${
          data.TestResults[1].power
        }W`}</text>
        {/* 5min power */}
        <circle
          key="pdc-5min-power-point"
          className="chart-pdc-cp-point"
          cx={xScale(dur5min)}
          cy={yScale(pwr5min)}
          r="5"
        />
        <text
          key="pdc-5min-power-point-text"
          className="chart-pdc-test-point-text"
          x={xScale(dur5min)}
          y={yScale(pwr5min) - 20}
        >{`5 min Power: ${pwr5min}W`}</text>
        {/* CP line */}
        <line
          key="pdc-cp-line"
          className="chart-pdc-cp-line"
          x1={0}
          y1={yScale(data.CriticalPower.cp)}
          x2={innerWidth}
          y2={yScale(data.CriticalPower.cp)}
        />
        <text
          key="pdc-cp-text"
          className="chart-pdc-cp-text"
          x={innerWidth}
          y={yScale(data.CriticalPower.cp) + 5}
        >{`CP = ${Math.floor(data.CriticalPower.cp)}W`}</text>
      </g>
    </ChartWrapper>
  );
};
