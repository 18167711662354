import { BrowserRouter as Router } from 'react-router-dom';

import CookieConsent from 'react-cookie-consent';

import { NavBar } from '../NavBar';
import { Content } from '../Content';
import { Footer } from '../Footer';

export const MainLayout = () => (
  <>
    <Router>
      <NavBar />
      <Content />
      <Footer />
    </Router>
    <CookieConsent buttonStyle={{ color: '#4e200b', fontSize: '13px' }}>
      This website uses cookies to enhance the user experience.
    </CookieConsent>
  </>
);
