import { PageLayout } from '../../components/PageLayout';

export const Home = () => (
  <>
    <PageLayout title="Welcome to yrchern.net">
      This is Yuh-Rong. I love running and programming. Hope you could find
      something that interests you here.
    </PageLayout>
  </>
);
