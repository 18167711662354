import { GlobalStyle } from './assets/styles/GlobalStyle';
import { MainLayout } from './components/MainLayout';

function App() {
  return (
    <>
      <GlobalStyle />
      <div>
        <MainLayout />
      </div>
    </>
  );
}

export default App;
