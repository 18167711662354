import styled from 'styled-components';

export const ControlContainerWrapper = styled.div`
  /* width: 800px; */

  padding-top: 10px;
  padding-right: 15px;
  padding-bottom: 15px;
  padding-left: 15px;

  color: black;
  background-color: white;

  .space {
    width: 8px;
    height: auto;
    display: inline-block;
  }
`;
