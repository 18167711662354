// second, minute, hour, day
const timeComponentMultipliers = [1, 60, 60 * 60, 60 * 60 * 24];
const secondsInHalfHour = 30 * 60;

const raceDurationToSeconds = (durationString) => {
  const timeComponents = durationString.split(':').reverse();
  let numSeconds = 0;
  timeComponents.forEach((cString, i) => {
    numSeconds = numSeconds + +cString * timeComponentMultipliers[i];
  });
  return numSeconds;
};

// seconds to hh:mm:ss
const secondsToRaceDurationString = (seconds) => {
  let t = seconds;
  const hh = Math.floor(t / timeComponentMultipliers[2]);
  t = t % timeComponentMultipliers[2];
  const mm = Math.floor(t / timeComponentMultipliers[1]);
  t = t % timeComponentMultipliers[1];
  const ss = t;
  return `${hh > 9 ? hh : `0${hh}`}:${mm > 9 ? mm : `0${mm}`}:${
    ss > 9 ? ss : `0${ss}`
  }`;
};

// hh:mm:ss to seconds
const durationStringToSeconds = (durationString) => {
  const timeArray = durationString.split(':');
  if (timeArray.length === 3)
    return +timeArray[0] * 60 * 60 + +timeArray[1] * 60 + +timeArray[2];
  else if (timeArray.length === 2) return +timeArray[0] * 60 + +timeArray[1];
  else if (timeArray.length === 1) return +timeArray[0];
  return 0;
};

const secondsToTimeTick = (seconds) => {
  let t = seconds;
  const hh = Math.floor(t / timeComponentMultipliers[2]);
  t = t % timeComponentMultipliers[2];
  const mm = Math.floor(t / timeComponentMultipliers[1]);
  t = t % timeComponentMultipliers[1];
  return `${hh > 9 ? hh : `0${hh}`}:${mm > 9 ? mm : `0${mm}`}`;
};

const getNiceDurationBound = (seconds) => {
  let niceBound = 0;
  while (niceBound <= seconds) niceBound = niceBound + secondsInHalfHour;
  return niceBound;
};

export {
  raceDurationToSeconds,
  getNiceDurationBound,
  secondsToRaceDurationString,
  durationStringToSeconds,
  secondsToTimeTick,
};
