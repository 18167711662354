const layout600 = {
  width: 600,
  height: 400,
  margin: { top: 15, right: 15, bottom: 55, left: 55 },
};

const layout800 = {
  width: 800,
  height: 600,
  margin: { top: 15, right: 15, bottom: 85, left: 85 },
};

const layout400 = {
  width: 400,
  height: 300,
  margin: { top: 5, right: 5, bottom: 55, left: 55 },
};

const layoutPdc = {
  width: 800,
  height: 400,
  margin: { top: 15, right: 15, bottom: 85, left: 85 },
};

export { layout400, layout600, layout800, layoutPdc };
