import { PageLayout } from '../../../components/PageLayout';
import { RaceRecordsChart } from '../../../charts/RaceRecordsChart';

export const RunningRecords = () => (
  <>
    <PageLayout title="My Race Records">
      <RaceRecordsChart />
    </PageLayout>
  </>
);
