// in seconds
// 5s, 1m, 3m, 5m, 10m, 15m, 20m, 40m, 1h
const PdcDurations = [120, 180, 300, 600, 900, 1200, 2400, 3600];

const calculateCPAndWprime = (p1, t1, p2, t2) => {
  // y = mx + b
  // m: slope
  // b = y - mx
  // b: cp
  // wPrimeJoules is the slope of the line
  // (1/t1, p1) and (1/t2, p2)
  let wPrimeJoules = (p2 - p1) / (1 / t2 - 1 / t1);
  let wPrimeKilojoules = wPrimeJoules / 1000;
  // cp is the y value at p = 0
  let cp = p1 - wPrimeJoules * (1 / t1);

  return {
    cp,
    wPrime: wPrimeKilojoules,
  };
};

const calculatePowerAtDuration = (CriticalPower, duration) =>
  CriticalPower.wPrime * 1000 * (1 / duration) + CriticalPower.cp;

// wPrime in KJ
const calculatePdcPoints = (CriticalPower, durations = PdcDurations) => {
  // y = mx + b = wPrime*1000 + cp
  // power = m * (1/duration) + cp
  // return array of [duration, Power]
  return durations.map((d) => ({
    duration: d,
    power: calculatePowerAtDuration(CriticalPower, d),
  }));
};

const secondsToShortTimeString = (sec) => {
  let reminder = sec;
  // hour
  let h;
  if (reminder < 3600) h = '';
  else h = `${Math.floor(reminder / 3600)}h`;
  reminder = reminder % 3600;
  // minute
  let m;
  if (reminder < 60) m = '';
  else m = `${Math.floor(reminder / 60)}m`;
  reminder = reminder % 60;
  // second
  let s;
  if (reminder === 0) s = '';
  else s = `${reminder}s`;
  return `${h}${m}${s}`;
};

const isCriticalPowerValid = (CriticalPower) => {
  if (CriticalPower.wPrime <= 0)
    return {
      result: false,
      reason: 'Longer duration should have lower power.',
    };
  return { result: true };
};

export {
  PdcDurations,
  calculateCPAndWprime,
  calculatePdcPoints,
  secondsToShortTimeString,
  calculatePowerAtDuration,
  isCriticalPowerValid,
};
