export const AxisLeft = ({
  yScale,
  innerHeight,
  ticks,
  tickFormat = (t) => t,
  tickTextOffset = -20,
  axisLabelText,
  axisLabelTextOffset = -50,
  lineStyleClassname = 'chart-axis-line',
  tickLineStyleClassname = 'chart-axis-tick-line',
  tickTextStyleClassname = 'chart-axis-tick-text',
  axisLabelClassname = 'chart-axis-label-text',
}) => {
  const axisTicks = ticks ? ticks : yScale.ticks();
  const axisY1 = yScale(axisTicks[0]);
  const axisY2 = yScale(axisTicks[axisTicks.length - 1]);
  const tickLength = 5;

  return (
    <g key="axis-left">
      <line
        className={lineStyleClassname}
        x1={0 + 0.5}
        y1={axisY1}
        x2={0 + 0.5}
        y2={axisY2}
      />
      {axisTicks.map((tickValue) => (
        <g
          id={`tick-${tickValue}`}
          key={tickValue.toString()}
          transform={`translate(0, ${yScale(tickValue)})`}
        >
          <line
            className={tickLineStyleClassname}
            x1={0}
            y1={0.5}
            x2={-tickLength}
            y2={0.5}
          />
          <text className={tickTextStyleClassname} x={tickTextOffset}>
            {tickFormat ? tickFormat(tickValue) : tickValue}
          </text>
        </g>
      ))}
      {/* axis label */}
      {axisLabelText && (
        <text
          className={axisLabelClassname}
          transform={`translate(${axisLabelTextOffset},${
            innerHeight / 2
          }) rotate(-90)`}
        >
          {axisLabelText}
        </text>
      )}
    </g>
  );
};
