import { useRef } from 'react';
// import { Button } from 'react-bootstrap';

// import { useD3 } from '../../../hooks/useD3';

export const ChartWrapper = ({ layout, children }) => {
  const svgRef = useRef();

  return (
    <div>
      {/* <Button variant="secondary" size="sm" className="chart-svg-dl-button">
        Download
      </Button> */}
      <svg
        ref={svgRef}
        viewBox={`0 0 ${layout.width} ${layout.height}`}
        // width={layout.width} height={layout.height}
      >
        <rect width="100%" height="100%" fill="#fafafa" />
        {children}
      </svg>
    </div>
  );
};
