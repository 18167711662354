// need to pass innerWidth/innerHeight from callers
export const AxisBottom = ({
  xScale,
  innerWidth,
  innerHeight,
  ticks,
  tickFormat,
  tickTextOffset = 10,
  tickTextRotate = 0,
  axisLabelText,
  axisLabelTextOffset = -50,
  lineStyleClassname = 'chart-axis-line',
  tickLineStyleClassname = 'chart-axis-tick-line',
  tickTextStyleClassname = 'chart-axis-tick-text',
  axisLabelClassname = 'chart-axis-label-text',
}) => {
  const axisTicks = ticks ? ticks : xScale.ticks();
  const tickLength = 5;

  return (
    <g key="axis-bottom">
      {/* change to 0 to innerWidth later (need to modify all places using this) */}
      <line
        className={lineStyleClassname}
        x1={0}
        y1={innerHeight + 0.5}
        x2={innerWidth}
        y2={innerHeight + 0.5}
      />
      {axisTicks.map((tickValue) => (
        <g
          className="tick"
          key={tickValue.toString()}
          transform={`translate(${xScale(tickValue)},0)`}
        >
          <line
            className={tickLineStyleClassname}
            x1={0.5}
            y1={innerHeight + tickLength}
            x2={0.5}
            y2={innerHeight}
          />
          <text
            className={tickTextStyleClassname}
            transform={`translate(${0},${
              innerHeight + tickTextOffset
            }) rotate(${tickTextRotate})`}
          >
            {tickFormat ? tickFormat(tickValue) : tickValue}
          </text>
        </g>
      ))}
      {/* axis label */}
      <text
        className={axisLabelClassname}
        x={innerWidth / 2}
        y={innerHeight + axisLabelTextOffset}
      >
        {axisLabelText}
      </text>
    </g>
  );
};
