import { extent, max, min, scaleLinear, scaleLog } from 'd3';

import { ChartWrapper } from '../components/ChartWrapper';
import { layoutPdc as layout } from '../../config/chartLayout';
import { AxisLeft } from '../components/AxisLeft';
import { AxisBottom } from '../components/AxisBottom';
import { WaitingForDataChart } from '../components/WaitingForDataChart';

import {
  PdcDurations,
  calculatePdcPoints,
  secondsToShortTimeString,
} from '../../helpers/criticalPowerHelpers';

export const PowerVsReciprocalofTimeChart = ({ data }) => {
  const { width, height, margin } = layout;
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  if (!data) return <WaitingForDataChart layout={layout} />;

  const PdcPoints = calculatePdcPoints(data.CriticalPower);
  const xDomain = [0, 1 / min(PdcDurations)];
  const xScale = scaleLinear().domain(xDomain).range([0, innerWidth]);

  const xTicksValues = [120, 180, 300, 600, 900, 1200, 1800, 3600];
  const xTicks = xTicksValues.map((d) => 1 / d);
  let xTicksValMap = {};
  for (let i = 0; i < xTicksValues.length; i++) {
    xTicksValMap[`v${xTicks[i]}`] = xTicksValues[i];
  }

  const yDomain = [
    min([max(PdcPoints, (d) => d.power) * 0.7, data.CriticalPower.cp * 0.9]),
    max(PdcPoints, (d) => d.power) * 1.1,
  ];
  const yScale = scaleLinear().domain(yDomain).range([innerHeight, 0]).nice();

  // calculate two end points PdcDurations[0] PdcDurations[PdcDurations.length-1]
  // y = wPrime * 1000 * x + cp
  const dur1 = 1 / PdcDurations[0];
  const dur2 = 0; //PdcDurations[PdcDurations.length - 1];
  const pwr1 = data.CriticalPower.wPrime * 1000 * dur1 + data.CriticalPower.cp;
  const pwr2 = data.CriticalPower.wPrime * 1000 * dur2 + data.CriticalPower.cp;

  // 5 min power
  const dur5min = 300;
  const pwr5min = (
    data.CriticalPower.wPrime * 1000 * (1 / dur5min) +
    data.CriticalPower.cp
  ).toFixed(1);

  // TODO:
  // add tooltips
  // add W'
  // add CP line

  return (
    <ChartWrapper layout={layout}>
      <g
        key="p-vs-1-over-time-chart-canvas"
        id="p-vs-1-over-time-chart-canvas"
        transform={`translate(${margin.left},${margin.top})`}
      >
        <AxisBottom
          xScale={xScale}
          innerWidth={innerWidth}
          innerHeight={innerHeight}
          ticks={xTicks}
          tickFormat={(t) => secondsToShortTimeString(xTicksValMap[`v${t}`])}
          tickTextOffset={30}
          tickTextRotate={-30}
          axisLabelText="1/t (Showing t)"
          axisLabelTextOffset={70}
        />
        <AxisLeft
          yScale={yScale}
          innerHeight={innerHeight}
          tickTextOffset={-25}
          axisLabelText="Power (W)"
          axisLabelTextOffset={-60}
        />
        <line
          key="pdc-pdc-line"
          className="chart-pdc-line"
          x1={xScale(dur1)}
          y1={yScale(pwr1)}
          x2={xScale(dur2)}
          y2={yScale(pwr2)}
        />
        <line
          key="pdc-cp-line"
          className="chart-pdc-cp-line"
          x1={xScale(dur1)}
          y1={yScale(pwr2)}
          x2={xScale(dur2)}
          y2={yScale(pwr2)}
        />
        {/* Test Point #1 */}
        <circle
          key="pdc-test-point-1"
          className="chart-pdc-test-point"
          cx={xScale(1 / data.TestResults[0].duration)}
          cy={yScale(data.TestResults[0].power)}
          r="5"
        />
        <text
          key="pdc-test-point1-text"
          className="chart-pdc-test-point-text"
          x={xScale(1 / data.TestResults[0].duration)}
          y={yScale(data.TestResults[0].power) + 5}
        >{`Test #1: ${secondsToShortTimeString(data.TestResults[0].duration)}@${
          data.TestResults[0].power
        }W`}</text>
        {/* Test Point #2 */}
        <circle
          key="pdc-test-point-2"
          className="chart-pdc-test-point"
          cx={xScale(1 / data.TestResults[1].duration)}
          cy={yScale(data.TestResults[1].power)}
          r="5"
        />
        <text
          key="pdc-test-point2-text"
          className="chart-pdc-test-point-text"
          x={xScale(1 / data.TestResults[1].duration)}
          y={yScale(data.TestResults[1].power) + 5}
        >{`Test #2: ${secondsToShortTimeString(data.TestResults[1].duration)}@${
          data.TestResults[1].power
        }W`}</text>
        {/* CP Point */}
        <circle
          key="pdc-cp-point"
          className="chart-pdc-cp-point"
          cx={xScale(dur2)}
          cy={yScale(pwr2)}
          r="5"
        />
        <text
          key="pdc-cp-text"
          className="chart-pdc-cp-text"
          x={xScale(dur1)}
          y={yScale(pwr2) + 5}
        >{`CP = intersection with Y axis= ${Math.floor(
          data.CriticalPower.cp,
        )}W`}</text>
        {/* 5min Power Point */}
        <circle
          key="pdc-5min-point"
          className="chart-pdc-cp-point"
          cx={xScale(1 / dur5min)}
          cy={yScale(pwr5min)}
          r="5"
        />
        <text
          key="pdc-5min-point-text"
          className="chart-pdc-test-point-text"
          x={xScale(1 / dur5min)}
          y={yScale(pwr5min) + 5}
        >{`5 min Power: ${pwr5min}W`}</text>
        {/* W' */}
        <text
          key="pdc-wprime-text"
          className="chart-pdc-wprime-text"
          x={xScale(dur1) - 5}
          y={yScale(pwr1)}
        >{`W' = slope = ${data.CriticalPower.wPrime.toFixed(2)} kJ`}</text>
      </g>
    </ChartWrapper>
  );
};
