import { useEffect } from 'react';
import { select, selectAll } from 'd3';

import { calSvgTextSize } from '../../helpers/svgHelper';

const pointStyles = {
  true: 'chart-point-circle-01',
  false: 'chart-point-circle-02',
};

const tooltipTextStyle = 'chart-tooltip-text';

export const Marks = ({ xScale, yScale, records, innerWidth, innerHeight }) => {
  const textBoxMargin = 5;
  const tooltipOffset = 5;
  useEffect(() => {
    // generate line data
    let lineData = [];
    for (let i = 0; i < records.length - 1; i++)
      lineData.push({
        x1: xScale(records[i].date),
        y1: yScale(records[i].durationInSeconds),
        x2: xScale(records[i + 1].date),
        y2: yScale(records[i + 1].durationInSeconds),
      });
    // handling lines
    const lines = select('#mark-area')
      .selectAll('line')
      .data(lineData, (d) => `from-${d.x1}-${d.x2}`);

    lines.join(
      (enter) =>
        enter
          .append('line')
          .attr('class', 'chart-curve-line')
          .attr('x1', (d) => d.x1)
          .attr('y1', (d) => d.y1)
          .attr('x2', (d) => d.x2)
          .attr('y2', (d) => d.y2),
      (update) =>
        update
          .append('line')
          .attr('class', 'chart-curve-line')
          .attr('x1', (d) => d.x1)
          .attr('y1', (d) => d.y1)
          .attr('x2', (d) => d.x2)
          .attr('y2', (d) => d.y2),
      (exit) => exit.remove(),
    );
    // handling points
    const marks = select('#mark-area')
      .selectAll('circle')
      .data(records, (d) => d.event);

    // Safari does not support circle r in CSS?
    marks.join(
      (enter) =>
        enter
          .append('circle')
          .attr('class', (d) => pointStyles[d.official])
          .attr('cx', (d) => xScale(d.date))
          .attr('cy', (d) => yScale(d.durationInSeconds))
          .attr('r', 5)
          .on('mouseover', (_, d) => handleOnMouseEnter(d))
          .on('mouseleave', () => handleOnMouseLeave()),
      (update) =>
        update
          .append('circle')
          .attr('class', (d) => pointStyles[d.official])
          .attr('cx', (d) => xScale(d.date))
          .attr('cy', (d) => yScale(d.durationInSeconds))
          .attr('r', 5)
          .on('mouseover', (_, d) => handleOnMouseEnter(d))
          .on('mouseleave', () => handleOnMouseLeave()),
      (exit) => exit.transition().duration(500).attr('r', 0).remove(),
    );
    // eslint-disable-next-line
  }, [records]);

  const handleOnMouseEnter = (data) => {
    const tooltip = select('#mark-area').append('g').attr('id', 'tooltip');
    const raceDetails = select('#mark-area')
      .append('g')
      .attr('id', 'race-details');
    const tooltipText = data.result;

    const textBoxSize = calSvgTextSize(tooltipText, tooltipTextStyle);
    const x =
      xScale(data.date) < innerWidth / 2
        ? xScale(data.date) + tooltipOffset
        : xScale(data.date) - 3 * tooltipOffset - textBoxSize.width;

    const y =
      yScale(data.durationInSeconds) < innerHeight / 2
        ? yScale(data.durationInSeconds) + tooltipOffset
        : yScale(data.durationInSeconds) -
          3 * tooltipOffset -
          textBoxSize.height;
    tooltip
      .append('rect')
      .attr('class', 'chart-tooltip-box')
      .attr('x', x)
      .attr('y', y)
      .attr('rx', 3)
      .attr('width', textBoxSize.width + 2 * textBoxMargin)
      .attr('height', textBoxSize.height + 2 * textBoxMargin);
    tooltip
      .append('text')
      .text(tooltipText)
      .attr('class', tooltipTextStyle)
      .attr('x', x + textBoxMargin + textBoxSize.width / 2)
      .attr('y', y + textBoxMargin + textBoxSize.height / 2);
    raceDetails
      .append('text')
      .text(data.event)
      .attr('class', 'chart-in-chart-text')
      .attr('x', innerWidth)
      .attr('text-anchor', 'end');
    raceDetails
      .append('text')
      .text(data.dateString)
      .attr('class', 'chart-in-chart-text')
      .attr('x', innerWidth)
      .attr('y', 14)
      .attr('text-anchor', 'end');
  };

  const handleOnMouseLeave = () => {
    selectAll('#tooltip').remove();
    selectAll('#race-details').remove();
  };

  return <g id="mark-area"></g>;
};
