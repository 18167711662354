export const PageLayout = ({ title, description, children }) => {
  if (title) document.title = `${title} | - yrchern.net -`;
  else document.title = '- yrchern.net -';

  return (
    <>
      {(title || description) && (
        <div className="jumbotron">
          <div className="jumbotron-content">
            <div className="page-header">
              {title && <h1 className="page-title">{title}</h1>}
              {description && (
                <div className="page-description">{description}</div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="content global-margin">
        <div className="page-content">{children}</div>
      </div>
    </>
  );
};
