import { useParams } from 'react-router-dom';
import { Button, Col, Row } from 'react-bootstrap';

import { PageLayout } from '../../components/PageLayout';
// import { RunningRecords } from './RunningRecords';

export const RunningAndMe = () => {
  const { id } = useParams();

  // if (id === 'running-records') return <RunningRecords />;

  const handleGo = (url) => {
    window.open(url, '_self');
  };

  return (
    <PageLayout
      title="Running and Me"
      description="Something about running and me."
    >
      <Row>
        <Col className="col-12 col-sm-12 col-md-6 col-lg-4 col-lgxl-4 col-xxl-3">
          <h2>Race Records 參賽紀錄</h2>
          <p>
            Check out my race records.
            <br />
            看看我的參賽紀錄
          </p>
          <p>
            <Button
              variant="outline-dark"
              onClick={() => handleGo('running-and-me/running-records')}
            >
              Go &raquo;
            </Button>
          </p>
        </Col>
      </Row>
      <br />
    </PageLayout>
  );
};
