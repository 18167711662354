import preval from 'preval.macro';

export const Footer = () => {
  let d = new Date();
  let y = d.getFullYear();
  return (
    <div className="global-margin page-footer">
      &copy; 2014-{y} Yuh-Rong Chen (
      <a href="https://twitter.com/yrchern"> @yrchern </a>). Updated:{' '}
      {preval`module.exports = new Date().toLocaleDateString("en-US");`}
    </div>
  );
};
