import { useState } from 'react';

import { Button, Card, Col, Form, Row } from 'react-bootstrap';

import { PageLayout } from '../../../components/PageLayout';

import {
  calculateCPAndWprime,
  isCriticalPowerValid,
} from '../../../helpers/criticalPowerHelpers';
import { PowerVsReciprocalofTimeChart } from '../../../charts/CriticalPowerChart/PowerVsReciprocalofTimeChart';
import { PowerVsTimeChart } from '../../../charts/CriticalPowerChart/PowerVsTimeChart';
import { CpTestFormWrapper } from './CpTestFormWrapper.styles';

export const CriticalPowerCalculator = () => {
  const [duration1, setDuration1] = useState();
  const [power1, setPower1] = useState();
  const [duration2, setDuration2] = useState();
  const [power2, setPower2] = useState();

  const [criticalPowerData, setCriticalPowerData] = useState();

  const handleKeyDown = (event) => {
    // Allow only positive integers
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/\D/.test(keyValue) && keyCode !== 8 && keyCode !== 9) {
      event.preventDefault();
    }
  };

  const handleOnClick = () => {
    if (duration1 && duration2 && power1 && power2) {
      const criticalPower = calculateCPAndWprime(
        power1,
        duration1,
        power2,
        duration2,
      );
      setCriticalPowerData({
        CriticalPower: criticalPower,
        TestResults: [
          { duration: duration1, power: power1 },
          { duration: duration2, power: power2 },
        ],
        validity: isCriticalPowerValid(criticalPower),
      });
    } else {
      console.log('Missing data.');
    }
  };

  return (
    <>
      <PageLayout title="Critical Power Calculator">
        {/* Add some descriptions here. */}
        <CpTestFormWrapper>
          <Form>
            {/* Test #1 */}
            <Row className="mb-3">
              <Form.Group as={Col} className="col-4">
                <Form.Label column="sm">
                  Test #1 (Short)
                  <br />
                  Suggest between 3m (180) and 5m (300)
                </Form.Label>
              </Form.Group>
              <Form.Group
                as={Col}
                className="col-4"
                controlId="formGridDuration1"
              >
                <Form.Label column="sm">Duration</Form.Label>
                <Form.Control
                  type="number"
                  min="0"
                  placeholder="Duration (seconds)"
                  onKeyDown={handleKeyDown}
                  onChange={(event) => setDuration1(event.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} className="col-4" controlId="formGridPower1">
                <Form.Label column="sm">Power</Form.Label>
                <Form.Control
                  type="number"
                  min="0"
                  placeholder="Power (W)"
                  onKeyDown={handleKeyDown}
                  onChange={(event) => setPower1(event.target.value)}
                />
              </Form.Group>
            </Row>
            {/* Test #2 */}
            <Row className="mb-3">
              <Form.Group as={Col} className="col-4">
                <Form.Label column="sm">
                  Test #2 (Long)
                  <br />
                  Suggest between 10m (600) and 20m (1200)
                </Form.Label>
              </Form.Group>
              <Form.Group
                as={Col}
                className="col-4"
                controlId="formGridDuration2"
              >
                <Form.Label column="sm">Duration</Form.Label>
                <Form.Control
                  type="number"
                  min="0"
                  placeholder="Duration (seconds)"
                  onKeyDown={handleKeyDown}
                  onChange={(event) => setDuration2(event.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} className="col-4" controlId="formGridPower2">
                <Form.Label column="sm">Power</Form.Label>
                <Form.Control
                  type="number"
                  min="0"
                  placeholder="Power (W)"
                  onKeyDown={handleKeyDown}
                  onChange={(event) => setPower2(event.target.value)}
                />
              </Form.Group>
            </Row>
            {/* Submit */}
            <Row className="mb-3">
              {/* <Col></Col> */}
              <Col className="col-4">
                <Form.Text className="text-muted">
                  Data are only used locally on your computer, not sent to the
                  server.
                </Form.Text>
              </Col>
              <Col className="col-4"></Col>
              <Col className="col-4 my-3 d-flex justify-content-end">
                <Button variant="primary" onClick={handleOnClick}>
                  Calculate
                </Button>
              </Col>
            </Row>
          </Form>
        </CpTestFormWrapper>
        {criticalPowerData && (
          <Row>
            <Card>
              <Card.Title className="text-center">
                Calculation Result
              </Card.Title>
              <Card.Body className="text-center">
                <div
                  className={
                    criticalPowerData.validity.result === true
                      ? 'text-body'
                      : 'text-danger'
                  }
                >
                  Critical Power:{' '}
                  {criticalPowerData.CriticalPower.cp.toFixed(1)}
                  W
                  <br />
                  W': {criticalPowerData.CriticalPower.wPrime.toFixed(2)} kJ
                  <br />
                  {criticalPowerData.validity.result === false &&
                    `Invalid: ${criticalPowerData.validity.reason}`}
                </div>
              </Card.Body>
            </Card>
          </Row>
        )}
        <Row>
          <br />
        </Row>
        <Row>
          <Col className="col-12 col-sm-12 col-md-12 col-lg-12 col-lgxl-8 col-xxl-6">
            <PowerVsReciprocalofTimeChart data={criticalPowerData} />
            <p className="text-center">Fig: CP and W' Calculation Explained</p>
          </Col>
          <Col className="col-12 col-sm-12 col-md-12 col-lg-12 col-lgxl-8 col-xxl-6">
            <PowerVsTimeChart data={criticalPowerData} />
            <p className="text-center">Fig: Power Duration Curve</p>
          </Col>
        </Row>
        <hr />
        <h4>References:</h4>
        <ol className="page-quote">
          <li>
            <a
              href="https://www.highnorth.co.uk/articles/critical-power-calculator"
              target="_blank"
              rel="noreferrer"
            >
              Critical Power And W’ Explained
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/groups/PalladinoPowerProject"
              target="_blank"
              rel="noreferrer"
            >
              Palladino Power Project
            </a>
          </li>
        </ol>
      </PageLayout>
    </>
  );
};
