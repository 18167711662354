import { useState } from 'react';

import { Button, Col, Form, Row } from 'react-bootstrap';

import { PageLayout } from '../../../components/PageLayout';
import { PoundToKG, MileToKM } from '../../../data/constants';

import { ReFormWrapper } from './ReFormWrapper.styles';

const ResultTable = ({ re }) => (
  <>
    Based on above input, your RE is <b>{re.toFixed(2)}</b> or ({re.toFixed(4)}{' '}
    if you need more digits) .
  </>
);

export const RunningEffectivenessCalculator = () => {
  const [avgPower, setAvgPower] = useState();
  const [weight, setWeight] = useState();
  const [weightUnit, setWeightUnit] = useState('kg');
  const [duration, setDuration] = useState();
  const [distance, setDistance] = useState();
  const [distanceUnit, setDistanceUnit] = useState('KM');
  const [re, setRe] = useState();

  const handleWeightUnitChange = (event) => {
    setWeightUnit(event.target.value);
  };

  const handleDistanceUnitChange = (event) => {
    setDistanceUnit(event.target.value);
  };

  const handleKeyDown = (event) => {
    // Allow only positive integers
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/\D/.test(keyValue) && keyCode !== 8 && keyCode !== 9) {
      event.preventDefault();
    }
  };

  // to test if a var is a positive number: (typeof myVar === 'number' && myVar > 0)

  const handleOnClick = () => {
    if (avgPower && weight && duration && distance) {
      setRe(
        ((distanceUnit === 'Mile' ? distance * MileToKM : distance) * 1000) /
          duration /
          (avgPower / (weightUnit === 'lb' ? weight * PoundToKG : weight)),
      );
      // console.log(`RE = ${re.toFixed(2)}`);
    } else {
      // set error message
      console.log('Missing data.');
    }
  };

  return (
    <>
      <PageLayout title="Running Effectiveness Calculator">
        {/* Add some descriptions here. */}
        This calculator is used to calculate RE for a specific segment of your
        run. For race planning, please select the segment (longer than 10
        minutes, ideally near the end of your workout) executed at around your
        target power.
        <hr />
        <ReFormWrapper>
          <Form>
            <Row className="mb-3">
              <Form.Group as={Col} className="col-4">
                <Form.Label column="sm">
                  Average Power
                  <br />
                  (Watts)
                </Form.Label>
                <Form.Control
                  type="number"
                  min="0"
                  placeholder="Watts"
                  onKeyDown={handleKeyDown}
                  onChange={(event) => setAvgPower(event.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} className="col-4">
                <Form.Label column="sm">
                  Weight
                  <br />
                  (Weight setting of your power meter)
                </Form.Label>
                <Form.Control
                  type="number"
                  min="0"
                  placeholder={weightUnit == 'kg' ? 'KG' : 'LB'}
                  onKeyDown={handleKeyDown}
                  onChange={(event) => setWeight(event.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} className="col-4">
                <Form.Label column="sm">
                  Weight Unit
                  <br />1 lb = {PoundToKG} KG
                  <br />
                </Form.Label>
                <br />
                <Form.Check
                  inline
                  label="KG"
                  type="radio"
                  name="weight unit"
                  value="kg"
                  checked={weightUnit === 'kg'}
                  onChange={handleWeightUnitChange}
                />
                <Form.Check
                  inline
                  label="LB"
                  type="radio"
                  name="weight unit"
                  value="lb"
                  checked={weightUnit === 'lb'}
                  onChange={handleWeightUnitChange}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} className="col-4">
                <Form.Label column="sm">
                  Duration in seconds ({'>='} 600)
                  <br />
                  <br />
                </Form.Label>
                <Form.Control
                  type="number"
                  min="0"
                  placeholder="seconds"
                  onKeyDown={handleKeyDown}
                  onChange={(event) => setDuration(event.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} className="col-4">
                <Form.Label column="sm">
                  Distance
                  <br />
                  (of selected segment)
                </Form.Label>
                <Form.Control
                  type="number"
                  min="0"
                  pattern="[0-9]*"
                  placeholder={distanceUnit == 'KM' ? 'KM' : 'Mile'}
                  // onKeyDown={handleKeyDown2}
                  onChange={(event) => setDistance(event.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} className="col-4">
                <Form.Label column="sm">
                  Distance Unit
                  <br />1 mile = {MileToKM} KM
                  <br />
                </Form.Label>
                <br />
                <Form.Check
                  inline
                  label="KM"
                  type="radio"
                  name="distance unit"
                  value="KM"
                  checked={distanceUnit === 'KM'}
                  onChange={handleDistanceUnitChange}
                />
                <Form.Check
                  inline
                  label="Mile"
                  type="radio"
                  name="distance unit"
                  value="Mile"
                  checked={distanceUnit === 'Mile'}
                  onChange={handleDistanceUnitChange}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              {/* <Col></Col> */}
              <Col className="col-4">
                <Form.Text className="text-muted">
                  Data are only used locally on your computer, not sent to the
                  server.
                </Form.Text>
              </Col>
              <Col className="col-4 my-3 d-flex justify-content-end">
                <Button variant="primary" onClick={handleOnClick}>
                  Calculate
                </Button>
              </Col>
            </Row>
          </Form>
        </ReFormWrapper>
        <hr />
        <h2>Results</h2>
        {re ? (
          <ResultTable re={re} />
        ) : (
          <>
            Please fill the form and click <b>Calculate</b> button.
          </>
        )}
        <hr />
        <h4>References:</h4>
        <ol className="page-quote">
          <li>
            <a
              href="https://www.facebook.com/groups/PalladinoPowerProject"
              target="_blank"
              rel="noreferrer"
            >
              Palladino Power Project
            </a>
          </li>
          <li>
            <a
              href="https://www.powerpacing.run/key-concepts/running-effectiveness/"
              target="_blank"
              rel="noreferrer"
            >
              What is Running Effectiveness (RE)?
            </a>
          </li>
        </ol>
      </PageLayout>
    </>
  );
};
