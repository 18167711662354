import { Container, Nav, Navbar } from 'react-bootstrap';

import logo from '../../assets/images/logo.png';

const navBarItems = [
  { text: 'Home', path: '/' },
  { text: 'Running and Me', path: '/running-and-me' },
  { text: 'Running with Power', path: '/running-with-power' },
  { text: 'About', path: '/about' },
];

const getNavLinkClassName = (path) =>
  path === '/'
    ? window.location.pathname === path
      ? 'navbar-selected'
      : ''
    : window.location.pathname.indexOf(path) === 0
    ? 'navbar-selected'
    : '';

export const NavBar = () => {
  return (
    <Navbar bg="dark" variant="dark" fixed="top" role="navigation" expand="md">
      <Container className="global-margin">
        <Navbar.Brand href="/">
          <img src={logo} height="24" width="24" alt="嶸" /> yrchern.net
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {navBarItems.map((p) => (
              <Nav.Link
                key={`navbar-item-${p.text}`}
                className={getNavLinkClassName(p.path)}
                href={p.path}
              >
                {p.text}
              </Nav.Link>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
