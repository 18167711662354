import { useState } from 'react';
import { extent, max, scaleLinear, scaleTime } from 'd3';
import { Col, Form, Row } from 'react-bootstrap';

import { secondsToTimeTick } from '../../helpers/durationHelper';

import { ChartWrapper } from '../components/ChartWrapper';
import { layout800 as layout } from '../../config/chartLayout';
import { RaceRecords } from '../../data/RaceRecords';
// import { AxisBottom } from './AxisBottom';
import { AxisBottom } from '../components/AxisBottom';
// import { AxisLeft } from './AxisLeft';
import { AxisLeft } from '../components/AxisLeft';
import { Marks } from './Marks';
import { ControlContainerWrapper } from './RaceRecordsChart.style';

import {
  raceDurationToSeconds,
  getNiceDurationBound,
} from '../../helpers/durationHelper';

const durationLowerBoundByRace = {
  '50K': 12600,
  Marathon: 9000,
  'Half Marathon': 3600,
  '5K': 900,
  Parkrun: 900,
};

const tickIntervalByRace = {
  '50K': 1800,
  Marathon: 1800,
  'Half Marathon': 600,
  '5K': 300,
  Parkrun: 300,
};

export const RaceRecordsChart = () => {
  const [distance, setDistance] = useState('Marathon');

  const records = RaceRecords[distance].map((r) => ({
    ...r,
    dateString: r.date,
    date: new Date(r.date),
    durationInSeconds: raceDurationToSeconds(r.result),
  }));

  const { width, height, margin } = layout;
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  const xDomain = extent(records, (d) => d.date);
  const xScale = scaleTime().domain(xDomain).range([0, innerWidth]).nice();
  const niceDurationBound = getNiceDurationBound(
    max(records, (d) => d.durationInSeconds),
  );

  const yDomain = [durationLowerBoundByRace[distance], niceDurationBound];
  const yScale = scaleLinear().domain(yDomain).range([innerHeight, 0]);
  const ticks = Array.from(
    {
      length:
        (niceDurationBound - durationLowerBoundByRace[distance]) /
          tickIntervalByRace[distance] +
        1,
    },
    (_, i) =>
      durationLowerBoundByRace[distance] + i * tickIntervalByRace[distance],
  );

  return (
    <>
      <ControlContainerWrapper className="col text-end">
        <Form.Group as={Row} className="mb-3" controlId="formRaceDistances">
          <Form.Label column sm="6">
            Race Category:
          </Form.Label>
          <Col sm="6">
            <Form.Select
              defaultValue="Marathon"
              onChange={(e) => {
                setDistance(e.target.value);
              }}
            >
              {Object.keys(RaceRecords).map((t) => (
                <option value={t} key={t}>
                  {t}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Form.Group>
      </ControlContainerWrapper>
      <ChartWrapper layout={layout}>
        <g
          key="race-record-chart-canvas"
          id="race-record-chart-canvas"
          transform={`translate(${margin.left},${margin.top})`}
        >
          <AxisBottom
            xScale={xScale}
            innerWidth={innerWidth}
            innerHeight={innerHeight}
            // ticks={xScale.ticks()}
            tickFormat={(t) => t.getFullYear()}
            tickTextOffset={30}
            tickTextRotate={-30}
            axisLabelText="Date/Year"
            axisLabelTextOffset={70}
          />
          <AxisLeft
            yScale={yScale}
            innerHeight={innerHeight}
            ticks={ticks}
            tickFormat={secondsToTimeTick}
            tickTextOffset={-25}
            axisLabelText="Finish Time"
            axisLabelTextOffset={-60}
          />
          <Marks
            xScale={xScale}
            yScale={yScale}
            records={records}
            innerWidth={innerWidth}
            innerHeight={innerHeight}
          />
        </g>
      </ChartWrapper>
    </>
  );
};
