import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

.global-margin {
  margin-left: 10%;
  margin-right: 10%;
}

.content {
  /* margin-top: 20px;
  padding-top: 20px; */
}

.page-header {
  padding-top: 20px;
  padding-bottom: 20px;
}

.page-title {
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 50px;
  font-weight: normal;
}

.page-description {
  font-size: 20px;
  color: #999;
}

.page-content {
  font-size: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.page-subtitle {
  margin-top: 20px;
  margin-bottom: 15px;
  font-size: 40px;
}

.page-quote-border {
  background-color: #d9d9d9;
  margin-top: 20px;
  margin-bottom: 20px;
}

.page-quote {
  font-size: 16px;
  background-color: white;
  margin-left: 10px;
  padding-left: 10px;
}

.page-footer {
  padding: 20px 0;
  color: #999;
  font-size: 14px;
  text-align: left;
  border-top: 1px solid #e5e5e5;
}

.jumbotron {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
  color: inherit;
  background-color: #eee;
}

.jumbotron-content {
  padding: 30px;
  margin-left: 10%;
  margin-right: 10%;
}

.navbar-selected {
  color: #fff;
}

.navbar-seperator {
  font-size: 20px;
  color: #fff;
}

/* .chart-svg-div-600 {
  width: 600px;
  overflow: hidden;
  border: none;
  clear: both;
} */
  
.chart-svg-dl-button {
  float: right;
}

.chart-axis-line {
    stroke: #333333;
    stroke-width: 1;
  }

.chart-axis-label-text {
  text-anchor: middle;
  alignment-baseline: baseline;
  fill: #000000;
  font-size: 24px;
}

.chart-axis-tick-line {
  stroke: #999999;
  stroke-width: 1;
}

.chart-axis-tick-text {
  text-anchor: middle;
  fill: #000000;
  font-size: 12px;
}

.chart-point-circle-01 {
  r: 5;
  fill: #36b37e;
  opacity: 0.7;
}
.chart-point-circle-01:hover {
  r: 7;
  stroke: green;
  opacity: 0.7;
}

.chart-point-circle-02 {
  r: 5;
  fill: #e54545;
  opacity: 0.7;
}
.chart-point-circle-02:hover {
  r: 7;
  fill: red;
  opacity: 0.7;
}

.chart-curve-line {
  stroke: #aaaaaa;
  stroke-width: 1;
}
.chart-tooltip-box {
    stroke: #001b30;
    fill: #4f5a60;
    stroke-width: 1;
    fill-opacity: 0.9;
}
.chart-tooltip-text {
  fill: #ffffff;
  font-size: 12px;
  text-anchor: middle;
  alignment-baseline: middle;
}
.chart-in-chart-text {
  fill: #818a91;
  font-size: 12px;
}

.chart-pdc-line {
  stroke: #333;
  stroke-width: 1;
  fill: none;
}

.chart-pdc-cp-line {
  stroke: #666;
  stroke-width: 1;
  stroke-dasharray: 4 1;
}

.chart-pdc-test-point {
  r: 5;
  fill: #12c350;
  opacity: 0.9;
}
.chart-pdc-test-point:hover {
  r: 7;
  stroke: green;
  opacity: 0.9;
}

.chart-pdc-test-point-text {
  fill: #818a91;
  font-size: 16px;
  text-anchor: start;
  alignment-baseline: hanging;
}

.chart-pdc-cp-point {
  r: 5;
  fill: #c31250;
  opacity: 0.9;
}
.chart-pdc-cp-point:hover {
  r: 7;
  stroke: red;
  opacity: 0.9;
}

.chart-pdc-cp-text {
  fill: #818a91;
  font-size: 16px;
  text-anchor: end;
  alignment-baseline: hanging;
}

.chart-pdc-wprime-text {
  fill: #818a91;
  font-size: 16px;
  text-anchor: end;
  alignment-baseline: baseline;
}

.chart-waiting-for-data-text {
  fill: #818a91;
  font-size: 16px;
  text-anchor: middle;
  alignment-baseline: hanging;
}

`;
