import { ChartWrapper } from '../ChartWrapper';
export const WaitingForDataChart = ({ layout }) => (
  <ChartWrapper layout={layout}>
    <text
      key="pdc-cp-text"
      className="chart-waiting-for-data-text"
      x={layout.width / 2}
      y={layout.height / 2}
    >
      Waiting for data to draw chart.
    </text>
  </ChartWrapper>
);
