import { select } from 'd3';

const calSvgTextSize = (text, style) => {
  var container = select('body').append('svg');
  container
    .append('text')
    .text(text)
    .attr('class', style)
    .attr({ x: -99999, y: -99999 });
  var size = container.node().getBBox();
  container.remove();
  return { width: size.width, height: size.height };
};

export { calSvgTextSize };
